export enum FrontendAction {
  // dossiers
  DownloadPdf = 'download-pdf',
  DownloadDismissedPdf = 'download-dismissed-pdf',
  // dossiers close
  DossiersClose = 'close-dossier',
  // dossiers assign
  DossiersAssign = 'assign-dossier',
  // findings
  FindingChangeDecision = 'finding-change-decision',
  FindingDecide = 'finding-decide',
  FindingExpand = 'finding-expand',
  FindingClickSource = 'finding-click-source',
  // reviews
  UnorderedReview = 'unordered-review',
  // list view search
  ListViewSearch = 'list-view-search',
  // utm_source
  UtmSource = 'utm-source',
  // Claims Assist
  SwitchTabs = 'switch-tabs',
  SelectOverviewCategory = 'select-overview-category',
  ToggleOverviewEntryVisibility = 'toggle-overview-entry-visibility',
  ToggleOverviewCategoryVisiblity = 'toggle-overview-category-visibility',
  JumpToDocType = 'jump-to-doc-type',
  ClaimsAssistSummaryOverallFeedback = 'claims-assist-summary-overall-feedback',
  ClaimsAssistSearchResultFeedback = 'claims-assist-search-result-feedback',
  ClaimsAssistDatapointsSectionFeedback = 'claims-assist-datapoints-section-feedback',
  ClaimsAssistDeleteSummary = 'claims-assist-delete-summary',
}

export enum FeatureKeys {
  ReviewAssignments = 'review-assignments',
  Snooze = 'snooze',
  SnoozeExtendedExpiry = 'snooze-extended-expiry',
  Insights = 'insights',
  ReviewsListView = 'reviews-list-view',
  ReviewsListViewFull = 'reviews-list-view-full',
  ReviewsListViewHybrid = 'reviews-list-view-hybrid',
  Reinvestigations = 'reinvestigations',
  ReinvestigationsCanStart = 'can-start-reinvestigations',
  Screenshot = 'screenshot',
  WorkersComp = 'workers-comp',
  ReviewCounter = 'review-counter',
  DownloadDossiers = 'download-dossiers',
  IncludeFindingsCsvData = 'include-findings-csv-data',
  AllowUploadZip = 'allow-upload-zip',
  SkipUnorderedWarningPrompt = 'skip-unordered-warning-prompt',
  AdvancedInsights = 'advanced-insights',
  DisplayFindingsPriorityBanner = 'display-findings-priority-based-on-ai-banner',
  ClaimsAssist = 'claims-assist',
  ClaimsAssistReviews = 'claims-assist-reviews',
  ClaimsAssistDocuments = 'claims-assist-documents',
  ClaimsAssistInsights = 'claims-assist-insights',
  ClaimsAssistNotes = 'claims-assist-notes',
  ClaimsAssistSearch = 'claims-assist-search',
  ClaimsAssistDemo = 'claims-assist-demo',
  ClaimsAssistPocDocuments = 'claims-assist-poc-documents',
  ClaimsAssistPocOverview = 'claims-assist-poc-overview',
  ClaimsAssistPocNotes = 'claims-assist-poc-notes',
  ClaimsAssistFeedback = 'claims-assist-feedback',
  ClaimsAssistPocAskOwl = 'claims-assist-poc-ask-owl',
  ClaimsAssistDatapointFeedback = 'claims-assist-datapoint-feedback',
  ClaimsAssistDatapointsSectionFeedback = 'claims-assist-datapoints-section-feedback',
  ClaimsAssistOdg = 'claims-assist-odg',
  ClaimsAssistNonMedDocs = 'claims-assist-non-med-docs',
  ClaimsAssistFindings = 'claims-assist-findings',
  ClaimsAssistNotesOnlySummary = 'claims-assist-notes-only-summary',
  ClaimsAssistCombinedSummary = 'claims-assist-combined-summary',
  ClaimsAssistHideNonCombinedSummary = 'claims-assist-hide-non-combined-summary',
  EmailNotify24hrFileReady = '24hr-ready-email-notify',
  EmailNotifyAllFileReady = 'all-ready-email-notify',
  AcceptConclusionReasons = 'accept-conclusion-reasons',
  SocialProfilesInClaimInfo = 'social-profiles-in-claim-info',
  LtdClaimsBuckets = 'ltd-claims-buckets',
  DisablePremiumWaiverBucket = 'disable-premium-waiver-bucket',
}

export type FeaturesResponse = Array<FeatureKeys>;

export enum DataDogAction {
  DossierNameCellButton = 'dossier-name-cell-button',
  DossierPageHeaderTitle = 'dossier-page-header-title',
  ItemRowCustom = 'item-row-custom',
  ItemRowText = 'item-row-text',
  ItemRowUrl = 'item-row-url',
  RowItem = 'row-item',
  ValidationLink = 'validation-link',
  ScreenshotDeletionButton = 'screenshot-deletion-button',
  FeedbackDossierNameCellButton = 'feedback-dossier-name-cell-button',
}
